@import "src/styles/responsive";

.wrapper {
  width: 400px;
  background: var(--silverPolish000);
  border-radius: 12px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.04);
  padding: 32px 24px;
  &:hover {
    background-color: var(--silverPolish100);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 139px;
  gap: 16px;
}

.cardDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.title {
  font-size: 21px;
  font-family: "Space Grotesk";
  font-weight: 700;
  color: var(--silverPolish800);
}

@include device(mobile) {
  .wrapper {
    width: calc(100% - 48px);
  }
}
