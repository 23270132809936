@import "src/styles/responsive";

.wrapper {
  width: 100%;
  height: 100%;
  z-index: -1;
}

.firstColor {
  position: absolute;
  top: 80px;
  left: 130px;
  width: 620px;
  height: 500px;
  flex-shrink: 0;
  border-radius: 1111px;
  opacity: 0.25;
  background: var(--blueDacnis200);
  filter: blur(58.5px);
}

.secondColor {
  position: absolute;
  top: -40px;
  right: 160px;
  width: 620px;
  height: 500px;
  flex-shrink: 0;
  border-radius: 1111px;
  opacity: 0.25;
  background: var(--warmPink200);
  filter: blur(58.5px);
}

.thirdColor {
  position: absolute;
  top: 790px;
  left: 0;
  width: 620px;
  height: 500px;
  flex-shrink: 0;
  border-radius: 1111px;
  opacity: 0.25;
  background: var(--backgroundColorBlue);
  filter: blur(58.5px);
}

.lastColor {
  position: absolute;
  top: 602px;
  right: 0;
  width: 620px;
  height: 500px;
  flex-shrink: 0;
  border-radius: 1111px;
  opacity: 0.25;
  background: var(--backgroundColorOrange);
  filter: blur(58.5px);
}

@include device(laptopLarge) {
  .firstColor {
    left: 0;
  }
  .secondColor {
    right: 0;
  }
}

@include device(tablet) {
  .firstColor,
  .lastColor,
  .secondColor,
  .thirdColor {
    width: 100%;
  }
}
