@import "src/styles/responsive";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 88px;
  gap: 24px;
}

.title {
  max-width: 792px;
  color: var(--silverPolish800);
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

@include device(tablet) {
  .wrapper {
    margin-top: 32px;
  }
  .title {
    font-size: 36px;
  }
}
