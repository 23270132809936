@import "../styles/responsive.scss";

.homeWrapper {
  display: flex;
  flex-direction: column;
  gap: 240px;
}

.aboutCompanyWrapper {
  display: flex;
  flex-direction: column;
  gap: 200px;
}

.jobsListWrapper {
  display: flex;
  flex-direction: column;
  gap: 122px;
}

.contactUsWrapper {
  margin-top: 64px;
}

.termsAndConditionsPage,
.privacyPage,
.browseByLocationPage,
.browseByTitlePage {
  margin-bottom: 64px;
}

@include device(tablet) {
  .jobsListWrapper {
    gap: 56px;
  }

  .homeWrapper {
    gap: 120px;
  }
}
