@import "src/styles/responsive";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 64px;
}

.wrapperTitle {
  font-family: "Space Grotesk";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: var(--silverPolish800);
}

.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 64px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@include device(tablet) {
  .wrapper {
    justify-content: center;
    align-items: center;
  }
  .wrapperTitle {
    font-size: 28px;
    text-align: center;
  }

  .cardsWrapper {
    justify-content: center;
    align-items: center;
  }
}
