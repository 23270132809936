@import "src/styles/responsive";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 80px;
}

.content {
  max-width: 578px;
}

.contentTitle {
  font-family: "Space Grotesk";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: var(--silverPolish800);
  margin-bottom: 24px;
}

.sections {
  display: flex;
  flex-wrap: wrap;
  gap: 64px;
}

@include device(tablet) {
  .wrapper {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .contentTitle {
    font-size: 28px;
  }
  .content {
    text-align: center;
  }
  .sections {
    justify-content: center;
  }
}
