@mixin device($breakpoint) {
  @if $breakpoint == 4kDisplay {
    @media only screen and (min-width: 1240px) {
      @content;
    }
  }
  @if $breakpoint == laptopLarge {
    @media only screen and (max-width: 1440px) {
      @content;
    }
  }
  @if $breakpoint == laptopMedium {
    @media only screen and (max-width: 1240px) {
      @content;
    }
  }

  @if $breakpoint == laptopNormal {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @if $breakpoint == mobile {
    @media only screen and (max-width: 485px) {
      @content;
    }
  }
}

$maxWidth: 1920;
$maxWidthContainer: 1170;

@mixin adaptive-value(
  $property,
  $startSize,
  $minSize,
  $type,
  $important: false
) {
  $addSize: $startSize - $minSize;
  @if $type==1 {
    @media (max-width: #{$maxWidthContainer + px}) {
      #{$property}: calc(
          #{$minSize + px} + #{$addSize} *
            ((100vw - 320px) / #{$maxWidthContainer - 320})
        )
        if-important($important);
    }
  } @else if $type==2 {
    @media (min-width: #{$maxWidthContainer + px}) {
      #{$property}: calc(
          #{$minSize + px} + #{$addSize} *
            ((100vw - 320px) / #{$maxWidth - 320})
        )
        if-important($important);
    }
  } @else {
    #{$property}: calc(
        #{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320})
      )
      if-important($important);
  }
}
