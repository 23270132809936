@import "src/styles/responsive";
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}

.wrapperTitle {
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: var(--silverPolish800);
}

.cards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
}

@include device(mobile) {
  .cardLink {
    display: block;
    width: 100%;
  }
}
