@import "src/styles/responsive";

.wrapper {
  max-width: 380px;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  font-family: "Space Grotesk";
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: var(--silverPolish800);
}

@include device(tablet) {
  .wrapper {
    flex-direction: row;
    height: fit-content;
  }
}
